module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"ttz2eed"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-180789611-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Socieci","short_name":"Socieci","lang":"en","icons":[{"src":"/images/icons/icon-1024.png","sizes":"1024","type":"image/png"},{"src":"/images/icons/icon-256.png","sizes":"256","type":"image/png"},{"src":"/images/icons/icon-192.png","sizes":"192","type":"image/png"},{"src":"/images/icons/icon-172.png","sizes":"172","type":"image/png"},{"src":"/images/icons/icon-144.png","sizes":"144","type":"image/png"},{"src":"/images/icons/icon-128.png","sizes":"128","type":"image/png"},{"src":"/images/icons/icon-96.png","sizes":"96","type":"image/png"},{"src":"/images/icons/icon-72.png","sizes":"72","type":"image/png"},{"src":"/images/icons/icon-64.png","sizes":"64","type":"image/png"},{"src":"/images/icons/icon-48.png","sizes":"48","type":"image/png"},{"src":"/images/icons/icon-32.png","sizes":"32","type":"image/png"},{"src":"/images/icons/icon-16.png","sizes":"16","type":"image/png"}],"start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
